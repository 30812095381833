<template>
  <div class="form-cont">
    <div class="ltl-box">
      <a-form ref="formRef" size="medium" :model="form" layout="vertical" @submitSuccess="validateSuccess">
        <a-collapse :default-active-key="['1']" expand-icon-position="right">
          <a-collapse-item key="1">
            <template v-slot:header>
              <div class="user-title" @click.stop="">
                <div class="top-title">
                  <span class="identify">Origin</span>
                  <div class="address">
                    <span v-if="form.from_name">{{ form.from_name }},</span>
                    <span v-if="form.from_company">{{ form.from_company }},</span>
                    <span v-if="form.from_street1">{{ form.from_street1 }},</span>
                    <span v-if="form.from_street2">{{ form.from_street2 }},</span>
                    <span v-if="form.from_city">{{ form.from_city }},</span>
                    <span v-if="form.from_state">{{ form.from_state }}</span>
                  </div>
                </div>
                <div class="select-address">
                  <span class="selecr-title">Address</span>
                  <a-config-provider :locale="enUS">
                    <a-select @change="senderAddressVal">
                      <a-option v-for="item, i in senderAddress.option" :value="item.name" :key="i">{{ item.name }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </div>
              </div>
            </template>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-form-item field="from_name" label="Username" :rules="[
  { required: true, message: 'name is required' },
  { maxLength: 35, message: 'The value cannot exceed 35 characters' }
]" :validate-trigger="['change', 'input', 'focus', 'blur']">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="required 1-35 characters" :filter-option="false"
                      v-model="form.from_name" @change="fromNameChange" @search="fromNameSearch($event, '1')"
                      allow-clear>
                      <a-option v-for="item of fromNameOption" :value="item.name" :key="item">{{ item.name }}</a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="from_company" label="Company Name" :rules="[
  { maxLength: 35 }
]" :validate-trigger="['change', 'input']">
                  <a-input v-model="form.from_company" placeholder="required 1-35 characters" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-form-item field="from_street1" label="Address Line 1" :rules="[
  { required: true, message: 'street1 is required' },
  { maxLength: 35 }
]" :validate-trigger="['change', 'input']">
                  <a-input v-model="form.from_street1" placeholder="required 1-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="from_street2" label="Address Line 2" :rules="[{ maxLength: 35 }]"
                  :validate-trigger="['change', 'input']">
                  <a-input v-model="form.from_street2" placeholder="required 1-35 characters" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <a-col :span="12">
                    <a-form-item field="from_city" label="City" :rules="[
  { required: true, message: 'city is required' },
  { maxLength: 20 }
]" :validate-trigger="['change', 'input']">
                      <a-input v-model="form.from_city" placeholder="required 1-20 characters" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item field="from_state" label="State"
                      :rules="[{ required: true, message: 'state is required' }]"
                      :validate-trigger="['change', 'input']">
                      <a-select v-model="form.from_state" placeholder="Please select">
                        <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                        </a-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <a-col :span="12">
                    <a-form-item field="from_zip" label="Zip" :rules="[
  {
    required: true,
    type: 'number',
    message: 'zip is required'
  },
  { minLength: 5, maxLength: 10 }
]" :validate-trigger="['change', 'input']">
                      <a-config-provider :locale="enUS">
                        <a-select allow-search placeholder="required 5-10 characters" :filter-option="false"
                          v-model="form.from_zip" @change="fromChange" @search="fromZipSearch" allow-clear>
                          <a-option v-for="item of fromOptions" :value="item.zip" :key="item">{{ item.zip }}</a-option>
                        </a-select>
                      </a-config-provider>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item field="from_country" label="Country" :rules="[
  { required: true, message: 'country is required' }
]" :validate-trigger="['change', 'input']">
                      <a-select v-model="form.from_country" placeholder="Please select" disabled>
                        <a-option value="US">United State</a-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <a-col :span="12">
                    <a-row :gutter="[20, 20]">
                      <a-col :span="16">
                        <a-form-item field="from_phone" label="Phone" :rules="[
  { required: true, message: 'phone is required' },
  { minLength: 10, maxLength: 15 }
]" :validate-trigger="['blur']">
                          <a-input v-model="form.from_phone" placeholder="required 10-15 characters" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="8">
                        <a-form-item field="from_extension" label="Extension">
                          <a-input v-model="form.from_extension" placeholder="option" />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item field="from_pickSites" label="Non Commercial Pickup Sites">
                      <a-select v-model="form.from_pickSites" placeholder="Please select">
                        <a-option :value="i + 1" v-for="val, k, i in nonCommercialPickupSites" :key="k">{{ val }}
                        </a-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-form-item field="from_pickdate" label="Pickup Date" :rules="[
  { required: true, message: 'pickup date is required' }
]" :validate-trigger="['change', 'input']">
                  <a-config-provider :locale="enUS">
                    <a-date-picker v-model="form.from_pickdate" placeholder="Option Date" />
                  </a-config-provider>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="[20, 20]">
              <a-col :span="4">
                <a-form-item field="shipdate" label="Site Type">
                  <a-radio-group type="button" v-model="form.from_sitetype">
                    <a-radio :value="i + 1" v-for="it, ke, i in shipperSiteTypes" :key="ke">{{ it }}</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="19" :offset="1">
                <a-form-item field="from_accessorials" label="Accessorials">
                  <a-checkbox-group type="button" v-model="form.from_accessorials">
                    <a-checkbox :value="key" v-for="item, key, i in shipperAccessorials" :key="i">{{ item }}
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-item>
        </a-collapse>
        <a-collapse :default-active-key="['1']" expand-icon-position="right" style="margin-top: 24px">
          <a-collapse-item key="1">
            <template v-slot:header>
              <div class="user-title" @click.stop="">
                <div class="top-title">
                  <span class="identify">Destination</span>
                  <div class="address">
                    <span v-if="form.to_name">{{ form.to_name }},</span>
                    <span v-if="form.to_company">{{ form.to_company }},</span>
                    <span v-if="form.to_street1">{{ form.to_street1 }},</span>
                    <span v-if="form.to_street2">{{ form.to_street2 }},</span>
                    <span v-if="form.to_city">{{ form.to_city }},</span>
                    <span v-if="form.to_state">{{ form.to_state }}</span>
                  </div>
                </div>
                <div class="select-address">
                  <span class="selecr-title">Address</span>
                  <a-config-provider :locale="enUS">
                    <a-select @change="toAddressVal">
                      <a-option v-for="item, i in toAddress.option" :value="item.name" :key="i">{{ item.name }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </div>
              </div>
            </template>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-form-item field="to_name" label="Username" :rules="[
  { required: true, message: 'name is required' },
  { maxLength: 35, message: 'The value cannot exceed 35 characters' }
]" :validate-trigger="['change', 'input', 'focus', 'blur']">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="required 1-35 characters" :filter-option="false"
                      v-model="form.to_name" @change="toNameChange" @search="toNameSearch($event, '2')" allow-clear>
                      <a-option v-for="item of toNameOption" :value="item.name" :key="item">{{ item.name }}</a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_company" label="Company Name" :rules="[
  { maxLength: 35 }
]" :validate-trigger="['change', 'input']">
                  <a-input v-model="form.to_company" placeholder="required 1-35 characters" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-form-item field="to_street1" label="Address Line 1" :rules="[
  { required: true, message: 'address line 1 is required' },
  { maxLength: 35 }
]" :validate-trigger="['change', 'input']">
                  <a-input v-model="form.to_street1" placeholder="required 1-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_street2" label="Address Line 2" :rules="[{ maxLength: 35 }]"
                  :validate-trigger="['change', 'input']">
                  <a-input v-model="form.to_street2" placeholder="required 1-35 characters" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <a-col :span="12">
                    <a-form-item field="to_city" label="City" :rules="[
  { required: true, message: 'city is required' },
  { maxLength: 20 }
]" :validate-trigger="['change', 'input']">
                      <a-input v-model="form.to_city" placeholder="required 1-20 characters" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item field="to_state" label="State" :rules="[
  { required: true, message: 'state is required' }
]" :validate-trigger="['change', 'input']">
                      <a-select v-model="form.to_state" placeholder="Please select">
                        <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                        </a-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <a-col :span="12">
                    <a-form-item field="to_zip" label="Zip" :rules="[
  {
    required: true,
    type: 'number',
    message: 'zip is required'
  },
  { minLength: 5, maxLength: 10 }
]" :validate-trigger="['change', 'input']">
                      <a-config-provider :locale="enUS">
                        <a-select allow-search placeholder="required 5-10 characters" :filter-option="false"
                          v-model="form.to_zip" @change="toChange" @search="toZipSearch">
                          <a-option v-for="item of toOptions" :value="item.zip" :key="item">{{ item.zip }}</a-option>
                        </a-select>
                      </a-config-provider>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item field="to_country" label="Country" :rules="[
  { required: true, message: 'country is required' }
]" :validate-trigger="['change', 'input']">
                      <a-select v-model="form.to_country" disabled placeholder="Please select">
                        <a-option value="US">United State</a-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-row :gutter="[20, 20]">
                  <a-col :span="12">
                    <a-row :gutter="[20, 20]">
                      <a-col :span="16">
                        <a-form-item field="to_phone" label="Phone" :rules="[
  { required: true, message: 'phone is required' },
  { minLength: 10, maxLength: 15 }
]" :validate-trigger="['blur']">
                          <a-input v-model="form.to_phone" placeholder="required 10-15 characters" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="8">
                        <a-form-item field="to_extension" label="Extension">
                          <a-input v-model="form.to_extension" placeholder="option" />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item field="to_pickSites" label="Non Commercial Pickup Sites">
                      <a-select v-model="form.to_pickSites" placeholder="Please select">
                        <a-option :value="i + 1" v-for="val, k, i in nonCommercialPickupSites" :key="k">{{ val }}
                        </a-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>

            <a-row :gutter="[20, 20]">
              <a-col :span="6">
                <a-form-item field="shipdate" label="Site Type">
                  <a-radio-group type="button" v-model="form.to_sitetype">
                    <a-radio :value="i + 1" v-for="it, ke, i in consigneeSiteTypes" :key="ke">{{ it }}</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="18">
                <a-form-item field="to_accessoriais" label="Accessorials">
                  <a-checkbox-group type="button" v-model="form.to_accessoriais">
                    <a-checkbox :value="k" v-for="val, k, i in consigneeAccessorials" :key="i">{{ val }}
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-item>
        </a-collapse>
        <a-collapse :default-active-key="['1']" style="margin-top: 20px" expand-icon-position="right" id="info">
          <a-collapse-item key="1" :show-expand-icon="false" disabled>
            <template v-slot:header>
              <div class="user-title">
                <div class="title">Shipment Items</div>
                <a-space>
                  <a-form :model="addPackageform" id="inputnumber">
                    <a-input-number v-model="addPackageform.packageNumber" mode="button" placeholder="+ 1-10shipments"
                      :max="10" :min="1" />
                  </a-form>
                  <a-button @click="addPackage" type="primary">Add Shipment(s)
                  </a-button>
                </a-space>
              </div>
            </template>
            <div v-for="(item, index) in form.shipmentItems" :key="index" id="shipment-item">
              <a-row :gutter="[8, 0]" style="height: 70px" :style="index > 0 ? 'margin-top:20px' : 'none'">
                <a-col :span="1">
                  <a-form-item label="No.">
                    <span style="color: #86909c">{{ index + 1 }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="4">
                  <a-form-item label="Unit Type" :field="'shipmentItems.' + index + '.unit_type'" :rules="[
  {
    required: true,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                    <a-select v-model="item.unit_type" placeholder="Please select ...">
                      <a-option v-for="item, k, i in unitType" :key="item" :value="i + 1">{{ item }}</a-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="2">
                  <a-form-item :field="'shipmentItems.' + index + '.of_units'" label="# of Units" :rules="[
  {
    required: true,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                    <a-input v-model="item.of_units" placeholder="Require" />
                  </a-form-item>
                </a-col>
                <a-col :span="2">
                  <a-form-item :field="'shipmentItems.' + index + '.nmfc'" label="NMFC">
                    <a-input v-model="item.nmfc" placeholder="Option" />
                  </a-form-item>
                </a-col>
                <!-- <a-col :span="4">
                  <a-form-item label="# of Units" required :content-flex="true" :merge-props="false">
                    <a-row :gutter="[10, 0]">
                      <a-col :span="12">
                        <a-form-item :field="'shipmentItems.' + index + '.of_units'" hide-label :rules="[
                          {
                            required: true,
                            message: 'required'
                          }
                        ]" :validate-trigger="['change', 'input']">
                          <a-input v-model="item.of_units" placeholder="Require" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-item :field="'shipmentItems.' + index + '.nmfc'" label="NMFC">
                          <a-input v-model="item.nmfc" placeholder="Option" />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-col> -->
                <a-col :span="2">
                  <a-form-item :field="'shipmentItems.' + index + '.class'" label="Class" :rules="[
  {
    required: true,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                    <a-select v-model="item.class" placeholder="Please select ...">
                      <a-option v-for="item, k, i in shipmentClasses" :key="item" :value="i">{{ item }}</a-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="Dimension(in)" required :content-flex="true" :merge-props="false">
                    <a-row :gutter="[10, 0]">
                      <a-col :span="6">
                        <a-form-item :field="'shipmentItems.' + index + '.length'" hide-label :rules="[
  {
    required: true,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                          <a-input v-model="item.length" placeholder="L" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="6">
                        <a-form-item :field="'shipmentItems.' + index + '.width'" hide-label :rules="[
  {
    required: true,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                          <a-input v-model="item.width" placeholder="W" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="7">
                        <a-form-item :field="'shipmentItems.' + index + '.height'" hide-label :rules="[
  {
    required: true,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                          <a-input v-model="item.height" placeholder="H" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="5" class="inFt">
                        <a-form-item :field="'shipmentItems.' + index + '.dim_unit'" hide-label>
                          <a-radio-group type="button" v-model="item.dim_unit">
                            <a-radio value="in">IN</a-radio>
                            <a-radio value="ft">FT</a-radio>
                          </a-radio-group>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-col>
                <a-col :span="3">
                  <a-form-item label="Weight" required :content-flex="true" :merge-props="false">
                    <a-row :gutter="[10, 0]">
                      <a-col :span="12">
                        <a-form-item :field="'shipmentItems.' + index + '.weight'" hide-label :rules="[
  {
    required: true,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                          <a-input v-model="item.weight" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="12" class="lbKg">
                        <a-form-item :field="'shipmentItems.' + index + '.weight_unit'" hide-label>
                          <a-radio-group type="button" v-model="item.weight_unit">
                            <a-radio value="lb">LB</a-radio>
                            <a-radio value="kg">KG</a-radio>
                          </a-radio-group>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-col>
                <a-col :span="3">
                  <a-form-item :field="'shipmentItems.' + index + '.density'" label="Density(lb/cu ft)" :rules="[
  {
    required: false,
    message: 'required'
  }
]" :validate-trigger="['change', 'input']">
                    <a-input v-model="item.density" placeholder="Option" />
                  </a-form-item>
                </a-col>
                <a-col :span="1" class="delete-btn">
                  <!-- <img src="../../../assets/images/order/del.png" alt="" @click="deleteItem(item, index)"> -->
                </a-col>
              </a-row>
              <div class="exta">
                <div class="tips">
                  Total Weight(LB): 0.00 Total Density(lb/cu ft): NaN Total
                  Volume(cu ft): 0.00
                </div>
                <div class="checkbox">
                  <a-form-item :field="'shipmentItems.' + index + '.property'">
                    <a-checkbox-group v-model="item.property">
                      <a-checkbox value="stackable">Stackable</a-checkbox>
                      <a-checkbox value="hazmat">Hazmat</a-checkbox>
                    </a-checkbox-group>
                  </a-form-item>
                </div>
              </div>
            </div>
          </a-collapse-item>
        </a-collapse>
        <a-collapse :default-active-key="['1']" style="margin-top: 20px" expand-icon-position="right" id="info">
          <a-collapse-item key="1" :show-expand-icon="false" disabled>
            <template v-slot:header>
              <div class="user-title">
                <div class="title">Additional Details&Service</div>
                <a-space>
                  <span class="title1">Service Type</span>
                  <a-form-item field="service_type" hide-label>
                    <a-radio-group type="button" v-model="form.service_type">
                      <a-radio v-for="item, k, i in ltlOrderServices" :key="k" :value="i
  + 1">{{ item }}</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-space>
              </div>
            </template>
            <div class="additional-detail">
              <div class="note">
                <div>Note：</div><br /> • &nbsp;Total Declared Value is currently set for this
                shipment, please adjust as necessary.<br />
                • &nbsp;Cost of Transportation should be included in the Total Declared
                Value of the shipment. <br />• &nbsp;For shipments larger than 12 linear
                ft. A manual quote is REQUIRED. Please CALL for an accurate quote.
              </div>
              <div class="additional-cont">
                <div class="issue">
                  Total Declared Value and Condition of Goods?
                </div>
                <div class="input-cont">
                  <div>
                    <a-form-item field="declared_value" hide-label>
                      <a-input :style="{ width: '320px' }" placeholder="Please enter" allow-clear
                        v-model="form.declared_value" />
                    </a-form-item>
                  </div>
                  <div>
                    <a-form-item field="declared_value_type" hide-label>
                      <a-radio-group type="button" v-model="form.declared_value_type">
                        <a-radio value="new">New</a-radio>
                        <a-radio value="used">Used</a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </div>
                </div>
                <div class="center-tip">See Restricted/Excluded Commodities</div>
                <div class="one-select">
                  <span>Would you like to make this shipment eligible for a Spot Quote?
                  </span>
                  <div>
                    <a-form-item field="spot_quote" hide-label>
                      <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="form.spot_quote"
                        :checked-value="1" :unchecked-value="0">
                        <template #checked> Yes </template>
                        <template #unchecked> NO </template>
                      </a-switch>
                    </a-form-item>
                  </div>
                </div>
                <div class="one-select">
                  <span>Does this Shipment contain an Extreme Length Item? </span>
                  <div>
                    <a-form-item field="contain_ext_length_item" hide-label>
                      <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="form.contain_ext_length_item"
                        :checked-value="1" :unchecked-value="0">
                        <template #checked> Yes </template>
                        <template #unchecked> NO </template>
                      </a-switch>
                    </a-form-item>
                  </div>
                </div>
                <div class="one-select">
                  <span>Does this shipment require Protect From Freeze? </span>
                  <div>
                    <a-form-item field="freeze_protect" hide-label>
                      <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="form.freeze_protect"
                        :checked-value="1" :unchecked-value="0">
                        <template #checked> Yes </template>
                        <template #unchecked> NO </template>
                      </a-switch>
                    </a-form-item>
                  </div>
                </div>
              </div>
            </div>
          </a-collapse-item>
        </a-collapse>
        <div class="subBtn">
          <a-space>
            <a-button type="outline" class="outline-btn" @click="confirmReset">Reset</a-button>
            <a-button type="primary" class="primary-btn" html-type="submit">Submit</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import local from '@/utils/local'
import { Modal, Message } from '@arco-design/web-vue'
import { useRouter } from 'vue-router'
// 引入api中的接口
import { getZipCode, getAddressByName, addressList } from '@/api/order.js'
import { ltlAdd } from '@/api/ltl.js'
// 获取公共信息
const state = JSON.parse(local.get('configInfo')).state
const nonCommercialPickupSites = JSON.parse(local.get('configInfo')).ltl_order_non_commercial_pickup_sites
const shipperSiteTypes = JSON.parse(local.get('configInfo')).ltl_order_shipper_site_types
const shipperAccessorials = JSON.parse(local.get('configInfo')).ltl_order_shipper_accessorials
const consigneeSiteTypes = JSON.parse(local.get('configInfo')).ltl_order_consignee_site_types
const consigneeAccessorials = JSON.parse(local.get('configInfo')).ltl_order_consignee_accessorials
const unitType = JSON.parse(local.get('configInfo')).ltl_shipment_unit_types
const shipmentClasses = JSON.parse(local.get('configInfo')).ltl_shipment_classes
const ltlOrderServices = JSON.parse(local.get('configInfo')).ltl_order_services
const router = useRouter()
const shipmentNumber = ref()
const form = reactive({
  from_name: '',
  from_company: '',
  from_street1: '',
  from_street2: '',
  from_city: '',
  from_state: '',
  from_country: 'US',
  from_zip: '',
  from_phone: '',
  from_extension: '',
  from_pickSites: '',
  from_pickdate: '',
  from_sitetype: 1,
  from_accessorials: [],
  to_name: '',
  to_company: '',
  to_street1: '',
  to_street2: '',
  to_city: '',
  to_state: '',
  to_country: 'US',
  to_zip: '',
  to_phone: '',
  to_extension: '',
  to_pickSites: '',
  to_sitetype: 1,
  to_accessoriais: [],
  service_type: 1,
  declared_value: '',
  declared_value_type: 'new',
  spot_quote: 0,
  contain_ext_length_item: 0,
  freeze_protect: 0,
  shipmentItems: [
    {
      unit_type: '',
      of_units: '',
      nmfc: '',
      class: '',
      length: '',
      width: '',
      height: '',
      dim_unit: 'in',
      weight: '',
      weight_unit: 'lb',
      density: '',
      property: ['stackable']
    }
  ]
})
// 发送人地址选择
const senderAddress = reactive({
  option: ''
})
const getSenderAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 1,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    senderAddress.option = msg.data
  }
}
getSenderAddress()
const senderAddressVal = (val) => {
  // console.log(val)
  senderAddress.option.forEach((item) => {
    if (item.name === val) {
      form.from_name = item.name
      form.from_company = item.company
      form.from_street1 = item.street1
      form.from_street2 = item.street2
      form.from_city = item.city
      form.from_state = item.state
      form.from_zip = item.zip
      form.from_phone = item.phone
      form.from_extension = item.extension
      form.from_email = item.email
    }
  })
}
// 发件人信息搜索自动回填
const fromNameOption = ref([])
const fromNameSearch = async (val, type) => {
  const msg = await getAddressByName({
    term: val,
    type: type
  })
  if (msg.code === 0 && msg.data.length > 0) {
    fromNameOption.value = msg.data
  } else {
    fromNameOption.value = []
    form.from_name = val
  }
}
const fromNameChange = (val) => {
  if (fromNameOption.value !== []) {
    fromNameOption.value.forEach((sender) => {
      if (sender.name === val) {
        form.from_name = sender.name
        form.from_company = sender.company
        form.from_street1 = sender.street1
        form.from_street2 = sender.street2
        form.from_city = sender.city
        form.from_state = sender.state
        form.from_zip = sender.zip
        form.from_phone = sender.phone
        form.from_extension = sender.extension
        form.from_email = sender.email
      }
    })
  }
}
// 收件人zip自动回填
const fromOptions = ref([])
const fromZipSearch = async (value) => {
  console.log(value)
  if (value.length > 2) {
    const msg = await getZipCode({
      term: value
    })
    if (msg.data) {
      fromOptions.value = msg.data
    } else {
      fromOptions.value = []
    }
  }
  if (value.length > 5) {
    form.from_zip = value
  }
}
// 通过zip回填sender
const fromChange = (value) => {
  if (fromOptions.value !== []) {
    fromOptions.value.forEach((item) => {
      if (item.value === value) {
        form.from_city = item.city
        form.from_state = item.state
        form.from_zip = item.zip
      }
    })
  }
}
// 收件人选择地址
const toAddress = reactive({
  option: []
})
const getToAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 2,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    toAddress.option = msg.data
  }
}
getToAddress()
const toAddressVal = (val) => {
  toAddress.option.forEach((item) => {
    if (item.name === val) {
      form.to_name = item.name
      form.to_company = item.company
      form.to_street1 = item.street1
      form.to_street2 = item.street2
      form.to_city = item.city
      form.to_state = item.state
      form.to_zip = item.zip
      form.to_phone = item.phone
      form.to_extension = item.extension
      form.to_email = item.email
    }
  })
}
// 收件人姓名搜索
const toNameOption = ref([])
const toNameSearch = async (val, type) => {
  // console.log(val, type)
  const msg = await getAddressByName({
    term: val,
    type: type
  })
  if (msg.code === 0 && msg.data.length > 0) {
    toNameOption.value = msg.data
  } else {
    toNameOption.value = []
    form.to_name = val
  }
}
const toNameChange = (val) => {
  if (toNameOption.value !== []) {
    toNameOption.value.forEach((recipient) => {
      if (recipient.name === val) {
        form.to_name = recipient.name
        form.to_company = recipient.company
        form.to_street1 = recipient.street1
        form.to_street2 = recipient.street2
        form.to_city = recipient.city
        form.to_state = recipient.state
        form.to_zip = recipient.zip
        form.to_phone = recipient.phone
        form.to_extension = recipient.extension
        form.to_email = recipient.email
        form.to_addresstype = recipient.is_residential
      }
    })
  }
}
// 收件人zip查询
const toOptions = ref([])
const toZipSearch = async (value) => {
  console.log(value)
  if (value.length > 2) {
    const msg = await getZipCode({
      term: value
    })
    if (msg.data) {
      toOptions.value = msg.data
    } else {
      toOptions.value = []
    }
  }
  if (value.length > 5) {
    form.to_zip = value
  }
}
// 通过zip回填recipient
const toChange = (value) => {
  if (toOptions.value !== []) {
    toOptions.value.forEach((item) => {
      if (item.value === value) {
        form.to_city = item.city
        form.to_state = item.state
        form.to_zip = item.zip
      }
    })
  }
}
const addPackageform = reactive({
  packageNumber: ''
})
// 添加包裹
function addPackage () {
  // console.log(addPackageform.packageNumber)
  if (addPackageform.packageNumber === '' || addPackageform.packageNumber === 1) {
    form.shipmentItems.push({
      unit_type: '',
      of_units: '',
      option: '',
      class: '',
      length: '',
      width: '',
      height: '',
      dim_unit: 'in',
      weight: '',
      weight_unit: 'lb',
      density: '',
      shipmentType: ['stackable']
    })
  } else if (addPackageform.packageNumber > 1) {
    for (let i = 0; i < addPackageform.packageNumber; i++) {
      form.shipmentItems.push({
        unit_type: '',
        of_units: '',
        option: '',
        class: '',
        length: '',
        width: '',
        height: '',
        dim_unit: 'in',
        weight: '',
        weight_unit: 'lb',
        density: '',
        shipmentProperty: ['stackable']
      })
    }
  }
}
// 删除包裹
function deleteItem (item, index) {
  if (form.shipmentItems.length > 1) {
    form.shipmentItems.splice(index, 1)
  }
}
const formRef = ref()
// 表单重置
function confirmReset () {
  Modal.warning({
    title: 'Reset',
    hideCancel: false,
    titleAlign: 'start',
    renderToBody: false,
    okText: 'Yes',
    cancelText: 'Cancel',
    content: 'Do you want to clear all the order information？',
    onOk: (done) => {
      formRef.value.resetFields()
    }
  })
  // setTimeout(() => {
  //   isEdit.value = false
  // }, 100)
}
// 验证成功提交
const validateSuccess = async () => {
  const msg = await ltlAdd({
    from_name: form.from_name,
    from_company: form.from_company,
    from_street1: form.from_street1,
    from_street2: form.from_street2,
    from_city: form.from_city,
    from_state: form.from_state,
    from_zip: form.from_zip,
    from_country: form.from_country,
    from_phone: form.from_phone,
    from_extension: form.from_extension,
    from_email: "",
    from_site_type: form.from_sitetype,
    from_accessorials: form.from_accessorials,
    non_com_pickup_site: form.from_pickSites,
    pickup_date: form.from_pickdate,
    to_name: form.to_name,
    to_company: form.to_company,
    to_street1: form.to_street1,
    to_street2: form.to_street2,
    to_city: form.to_city,
    to_state: form.to_state,
    to_zip: form.to_zip,
    to_country: form.to_country,
    to_phone: form.to_phone,
    to_extension: form.to_extension,
    to_email: "",
    to_site_type: form.to_sitetype,
    to_accessorials: form.to_accessoriais,
    non_com_delivery_site: form.to_pickSites,
    declared_value: form.declared_value,
    declared_value_type: form.declared_value_type,
    spot_quote: form.spot_quote,
    total_linear_ft: "",
    contain_ext_length_item: form.contain_ext_length_item,
    ext_length_ft: "",
    ext_bundles: "",
    freeze_protect: form.freeze_protect,
    service_type: form.service_type,
    shipments: form.shipmentItems
  })
  console.log(msg)
  if (msg.code === 0) {
    Message.success('Submited!')
    router.push('/order/ltl/listltl')
  }
}
// 计算density
// watch(() => form.shipmentItems, (newValue) => {
//   console.log(newValue)
//   newValue.forEach((item, i) => {
//     if (item.weight !== '' && item.length !== '' && item.height !== '') {
//       item.density = (item.weight / (item.length * item.width * item.height)).toFixed(2)
//     }
//   })
// }, { deep: true })
</script>

<style lang="less" scoped>
:deep(.arco-collapse-item-content) {
  background-color: #fff;
}

.ltl-box {
  margin: 24px 36px;
}

#shipment-item {
  .inFt {
    :deep(.arco-radio-button-content) {
      padding: 2px 6px;
    }
  }

  .lbKg {
    :deep(.arco-radio-button-content) {
      padding: 2px 8px;
    }
  }
}

.form-cont {
  box-sizing: border-box;
  width: 1344px;
  margin: 0 auto;

  .user-title {
    height: 63px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    :deep(.arco-form-item) {
      margin-bottom: 0;
    }

    .top-title {
      display: flex;

      .identify {
        font-weight: 400;
        font-size: 20px;
        padding-right: 20px;
      }

      .address {
        font-weight: 400;
        font-size: 14px;
        color: #99A0C5;
      }
    }

    .select-address {
      display: flex;

      .selecr-title {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        padding-right: 10px;
        width: 65px;
        line-height: 42px;
      }

      :deep(.arco-select-view-single) {
        border: 1px solid #F2F3F5;
        width: 220px;
        margin-right: 10px;
      }
    }
  }
}

:deep(.arco-picker) {
  width: 100%;
}

.additional-detail {
  line-height: 35px;
  display: flex;
  justify-content: space-between;

  .additional-cont {
    margin-bottom: 20px;

    .issue {
      font-weight: 400;
      font-size: 14px;
      color: #4e5969;
    }

    .input-cont {
      display: flex;
      justify-content: space-between;

      .arco-input-wrapper {
        margin-right: 200px;
      }
    }

    .center-tip {
      font-weight: 400;
      font-size: 12px;
      text-decoration-line: underline;
      color: #86909c;
    }

    .one-select {
      display: flex;

      span {
        display: inline-block;
        width: 520px;
        font-size: 14px;
        color: #4e5969;
      }
    }
  }

  .note {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #9C6767;
    margin-bottom: 20px;
    background: #FFFBEB;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 20px;
    width: 555px;
    box-sizing: border-box;

    div {
      font-weight: 700;
      font-size: 20px;
      // padding-bottom: 24px;
    }
  }
}

// 提交按钮
.subBtn {
  text-align: right;
  margin: 40px 0;
}
</style>
